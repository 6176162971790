import { get, post } from "../request";

// 员工列表
export const userList = (params) => get("/user/list", params);

// 添加员工
export const userAdd = (params) => post("/user/add", params);

// 编辑员工
export const userUpdate = (params) => post("/user/update", params);

// 删除员工
export const userDelete = (params) => post("/user/delete", params);

// 启用/禁用员工
export const userEnableDisable = (params) =>
  post("/user/enableDisable", params);

// 查看员工
export const userInfo = (params) => get("/user/getInfo", params);

// 员工重置密码
export const userResetPwd = (params) => post("/user/resetPwd", params);

// 部门列表
export const departmentList = (params) => get("/department/list", params);

// 添加部门
export const departmentAdd = (params) => post("/department/add", params);

// 修改部门
export const departmentUpdate = (params) => post("/department/update", params);

// 删除部门
export const departmentDelete = (params) => post("/department/delete", params);

// 审核部门
export const departmentAudit = (params) => post("/department/audit", params);

// 上/下线部门
export const departmentUpAndDown = (params) =>
  post("/department/upAndDown", params);

// 小组列表
export const groupList = (params) => get("/group/list", params);

// 添加小组
export const groupAdd = (params) => post("/group/add", params);

// 修改小组
export const groupUpdate = (params) => post("/group/update", params);

// 查看小组
export const groupGetInfo = (params) => get("/group/getInfo", params);

// 删除小组
export const groupDelete = (params) => post("/group/delete", params);

// 审核小组
export const groupAudit = (params) => post("/group/audit", params);

// 上/下线小组
export const groupUpAndDown = (params) => post("/group/upAndDown", params);

// 全部权限列表
export const permissionList = (params) => get("/permission/getList", params);

// 用户权限列表
export const userPermission = (params) => post("/menu/userPermission", params);

// 添加权限
export const addUserPermission = (params) =>
  post("/user/addUserPermission", params);

// 客户端版本列表
export const clientversionList = (params) => get("/clientversion/list", params);

// 添加客户端版本
export const clientversionAdd = (params) => post("/clientversion/add", params);

// 修改客户端版本
export const clientversionUpdate = (params) =>
  post("/clientversion/update", params);

// 删除客户端版本
export const clientversionDelete = (params) =>
  post("/clientversion/delete", params);

// 菜单权限列表
export const getMenuList = (params) => get("/menu/getMenuList", params);

// 添加菜单权限
export const addMenu = (params) => post("/menu/addMenu", params);

// 编辑菜单权限
export const updateMenu = (params) => post("/menu/updateMenu", params);

// 启用禁用菜单权限
export const enableDisable = (params) => post("/menu/enableDisable", params);

// 启用禁用菜单权限
export const getMenuInfo = (params) => get("/menu/getMenuInfo", params);
