<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="ruleForm">
      <el-form-item class="form-item" label="小组名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入小组名称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="小组状态" prop="status">
        <el-select v-model="form.status" placeholder="全部" clearable>
          <el-option label="待上线" value="1"></el-option>
          <el-option label="上线" value="2"></el-option>
          <el-option label="下线" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="审核状态" prop="audit_status">
        <el-select v-model="form.audit_status" placeholder="全部" clearable>
          <el-option label="待审核" value="1"></el-option>
          <el-option label="审核不通过" value="2"></el-option>
          <el-option label="审核通过" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="所属部门" prop="department_id">
        <el-select v-model="form.department_id" placeholder="全部" clearable>
          <el-option
            v-for="(item, index) in partList"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>小组列表</div>
        <el-button
          v-if="type.indexOf('添加') > -1"
          type="primary"
          @click="handGroupAdd"
          >添 加</el-button
        >
      </div>

      <!-- 列表 -->
      <el-table
        :data="tableGroup"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="groupLoading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="name"
          label="小组名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="count"
          label="小组人数"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="小组状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="audit_status"
          label="审核状态"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="创建时间"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="department_name"
          label="所属部门"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          show-overflow-tooltip
          min-width="200"
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="type.indexOf('编辑') > -1"
              @click="handleClickGroup(scope.row.id)"
              type="text"
              size="small"
              >编辑</el-button
            >
            <el-button
              @click="handleAuditGroup(scope.row.id)"
              v-if="
                type.indexOf('审核') > -1 && scope.row.audit_status === '待审核'
              "
              type="text"
              size="small"
              >审核</el-button
            >
            <el-button
              @click="handleUpDownGroup(scope.$index)"
              v-if="
                type.indexOf('上线/下线') > -1 &&
                scope.row.audit_status === '审核通过'
              "
              type="text"
              size="small"
              >{{ scope.row.status === "上线" ? "下线" : "上线" }}</el-button
            >
            <el-button
              v-if="
                type.indexOf('删除') > -1 && scope.row.audit_status === '待审核'
              "
              @click="handDeleteGroup(scope.row.id)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->

      <!-- 添加/编辑 -->
      <el-dialog
        :title="isAdd ? '添加小组' : '编辑小组'"
        width="500px"
        v-model="groupShow"
      >
        <el-form
          ref="groupForm"
          :rules="rules"
          :model="groupForm"
          label-width="100px"
        >
          <el-form-item label="小组名称：" prop="name">
            <el-input
              v-model="groupForm.name"
              placeholder="请输入小组名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属部门：" prop="department_id">
            <el-select
              v-model="groupForm.department_id"
              placeholder="请选择部门"
            >
              <el-option
                v-for="(item, index) in partList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="groupShow = false">取 消</el-button>
            <el-button type="primary" @click="handGroupSubmit('groupForm')"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 添加/编辑 -->

      <!-- 审核 -->
      <el-dialog title="审核小组" width="500px" v-model="groupAudit">
        <el-form
          ref="auditForm"
          :rules="auditRules"
          :model="groupAuditForm"
          label-width="100px"
        >
          <el-form-item label="审核：" prop="audit_status">
            <el-radio-group v-model="groupAuditForm.audit_status">
              <el-radio label="2">审核不通过</el-radio>
              <el-radio label="3">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              v-model="groupAuditForm.audit_remarks"
            ></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="groupAudit = false">取 消</el-button>
            <el-button type="primary" @click="handGroupAuditSubmit('auditForm')"
              >确 定</el-button
            >
          </span>
        </template>
      </el-dialog>
      <!-- 审核 -->
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  departmentList,
  groupList,
  groupAdd,
  groupUpdate,
  groupGetInfo,
  groupDelete,
  groupAudit,
  groupUpAndDown,
} from "@/api/apiList/system-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        page: 1,
        pageSize: 10,
        name: "",
        status: "",
        audit_status: "",
        department_id: "",
      },
      partList: [], // 部门列表
      tableGroup: [], // 小组列表
      groupLoading: true,
      isAdd: true,
      groupShow: false,
      groupForm: {
        id: "",
        name: "",
        department_id: "",
      },
      rules: {
        name: [{ required: true, message: "请输入小组名称", trigger: "blur" }],
        department_id: [
          { required: true, message: "请选择所属部门", trigger: "change" },
        ],
      },
      groupAudit: false,
      groupAuditForm: {
        id: "",
        audit_status: "",
        audit_remarks: "",
      },
      auditRules: {
        audit_status: [
          { required: true, message: "请选择审核状态", trigger: "change" },
        ],
      },
      pageSize: 10,
      currentPage: 1,
      total: 10,
      type: "", // 权限
    };
  },
  methods: {
    // 检索
    onSubmit() {
      this.form.page = 1;
      this.form.pageSize = 10;
      this.groupLoading = true;
      this.handGroupList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加
    handGroupAdd() {
      this.groupForm = {
        id: "",
        name: "",
        department_id: "",
      };
      this.isAdd = true;
      this.groupShow = true;
    },
    // 编辑
    handleClickGroup(e) {
      this.groupForm.id = e;
      let obj = {
        id: e,
      };
      groupGetInfo(obj).then((res) => {
        if (res.result === "200") {
          this.groupForm.id = e;
          this.groupForm.name = res.data.name;
          this.groupForm.department_id = res.data.department_id;
          this.isAdd = false;
          this.groupShow = true;
        }
      });
    },
    // 添加/编辑提交
    handGroupSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            groupAdd(this.groupForm).then((res) => {
              if (res.result === "200") {
                this.groupShow = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handGroupList();
              }
            });
          } else {
            groupUpdate(this.groupForm).then((res) => {
              if (res.result === "200") {
                this.groupShow = false;
                this.$message.success({
                  message: res.data.info,
                  type: "success",
                });
                this.handGroupList();
              } else if (res.result === 300019) {
                this.$message.warning({
                  message: res.msg,
                  type: "success",
                });
              }
            });
          }
        }
      });
    },
    // 审核
    handleAuditGroup(e) {
      this.groupAuditForm.id = e;
      this.groupAudit = true;
    },
    // 审核提交
    handGroupAuditSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          groupAudit(this.groupAuditForm).then((res) => {
            if (res.result === "200") {
              this.groupAudit = false;
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handGroupList();
            }
          });
        }
      });
    },
    // 上/下线
    handleUpDownGroup(e) {
      let type = this.tableGroup[e].status === "上线" ? "下线" : "上线";
      this.$confirm("确定" + type + "该小组？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: this.tableGroup[e].id,
            status: type === "下线" ? "3" : "2",
          };
          groupUpAndDown(obj).then((res) => {
            if (res.result === "200") {
              this.$message({
                type: "success",
                message: res.data.info,
              });
              this.handGroupList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除
    handDeleteGroup(e) {
      this.$confirm("确定删除该小组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            id: e,
          };
          groupDelete(obj).then((res) => {
            if (res.result === "200") {
              this.$message.success({
                message: res.data.info,
                type: "success",
              });
              this.handGroupList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handSizeChange(e) {
      this.groupLoading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handPartList();
    },
    handCurrentChange(e) {
      this.groupLoading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handPartList();
    },
    handGroupList() {
      groupList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.status) {
              case 1:
                item.status = "待上线";
                break;
              case 2:
                item.status = "上线";
                break;
              case 3:
                item.status = "下线";
                break;
            }
            switch (item.audit_status) {
              case 1:
                item.audit_status = "待审核";
                break;
              case 2:
                item.audit_status = "审核不通过";
                break;
              case 3:
                item.audit_status = "审核通过";
                break;
            }
          });
          this.tableGroup = res.data.data;
          this.total = res.data.total;
          this.groupLoading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    departmentList().then((res) => {
      if (res.result === "200") {
        this.partList = res.data.data.filter((item) => item.status === 2);
      }
    });
    this.handGroupList();
  },
};
</script>

<style lang="scss">
.department-delelte {
  margin-top: 10px;
}
</style>
